/**
 * @generated SignedSource<<a97413e0c57174e74d61dc8840e5f766>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_AssignedWorksiteFragment$data = {
  readonly assignedWorksiteId: string | null | undefined;
  readonly " $fragmentType": "ProjectBaseFields_AssignedWorksiteFragment";
};
export type ProjectBaseFields_AssignedWorksiteFragment$key = {
  readonly " $data"?: ProjectBaseFields_AssignedWorksiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_AssignedWorksiteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_AssignedWorksiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignedWorksiteId",
      "storageKey": null
    }
  ],
  "type": "ProjectInternalBase",
  "abstractKey": null
};

(node as any).hash = "ac68b424d5c9aa3b974c15a55c0043e2";

export default node;
