import { WorkPlanningStatus } from '../../__enums__/WorkPlanningStatus';

const dateTime = {
  sevenDays: '7 jours',
  fourteenDays: '14 jours',
  thirtyDays: '30 jours',
  sixtyDays: '60 jours',
};

const details = {
  section: {
    attachments: 'Pièces jointes',
    client: 'Client',
    equipmentBase: 'Équipement',
    project: 'Projet',
  },
  subsection: {
    date: 'Date',
  },
};

const list = {
  column: {
    friendlyId: 'ID',
    client: 'Client',
    worksite: 'Chantier',
    status: 'Statut',
    date: 'Date',
  },
};

export const workPlanning = {
  dateTime,
  details,
  dialog: {
    upgrade: {
      quote: {
        title: 'Créer une soumission',
        kind: 'Type de soumission',
      },
      serviceCall: {
        title: 'Créer un appel de service',
        kind: 'Type d’appel de service',
      },
      confirmButton: 'Ok',
    },
  },
  error: {
    dateInThePast: 'La date ne peut pas être dans le passé',
    worksiteIsRequired: 'Veuillez sélectionner un chantier',
    errorDuringUpgradeToServiceCall: 'Une erreur s’est produite pendant la création de l’appel de service',
    errorDuringUpgradeToQuote: 'Une erreur s’est produite pendant la création de la soumission',
  },
  field: {
    equipment: {
      kind: 'Genre d’équipement',
      type: 'Genre',
      boomConfiguration: 'Configuration de flèche',
      craneConfiguration: 'Configuration de grue',
      craneSelectionMode: 'Sélection de la grue',
      lifts: 'Levages',
      configurationList: 'Liste de configurations',
      configuration: 'Configuration',
      otherInformation: 'Autres informations',
    },
    project: {
      date: {
        arrival: 'Date d’arrivée',
        date: 'Date',
        duration: 'Durée des travaux',
        durationPerDay: 'Durée par jour',
        notes: 'Notes et informations complémentaires',
      },
    },
  },
  list,
  pages: {
    client: {
      title: 'Sélectionner un client',
      requirementDescriptionsTitle: 'Exigences client',
    },
    details: {
      copyTitle: 'Copier la planification des travaux',
      copyTitleShort: 'Copier la planif.',
      shortTitle: 'Planification',
      title: 'Planification des travaux',
    },
    list: {
      title: 'Planification des travaux',
    },
    project: {
      title: 'Informations du projet',
      requirementDescriptionsTitle: 'Exigences du chantier',
      subsection: {
        branches: 'Succursales',
        dateAndTime: 'Dates et heures',
        work: 'Travail',
        worksite: 'Chantier',
      },
    },
    equipment: {
      title: 'Équipement',
      crane: {
        title: 'Sélecteur de grue',
      },
      subsection: {
        craneSelector: 'Sélecteur de grue',
      },
    },
  },
  placeholder: {
    clientInfo: 'Entrez les informations du client',
    noAttachment: 'Aucune pièce jointe',
    noConfiguration: 'Aucune configuration',
    projectInfo: 'Entrez les informations du projet',
    equipmentInfo: 'Entrez les informations de l’équipement',
    selectConfiguration: 'Sélectionner une configuration',
  },
  section: {
    client: 'Client',
    project: 'Projet',
    equipment: 'Équipement',
  },
  status: {
    canceled: 'Annulé',
    completed: 'Terminé',
    inWriting: 'En saisie',
    locked: 'Verrouillé',
    worksiteSelected: 'Chantier sélectionné',
  } satisfies Record<WorkPlanningStatus, string>,
};
