/**
 * @generated SignedSource<<afd263225137167ba3478478f2de70b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BillingCodeCategory = "accessories" | "boomAndCounterWeightTransport" | "equipment" | "escort" | "fixedFees" | "flatbedTransport" | "labor" | "movingFees" | "otherFees" | "permit" | "rateKind" | "%future added value";
export type CostLineKind = "automatic" | "instantCalculator" | "manual" | "quoteCalculator" | "%future added value";
import { FragmentRefs } from "relay-runtime";
import { label as billingCodeLabelResolverType } from "../../../__resolvers__/BillingCodeLabelResolver";
// Type assertion validating that `billingCodeLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(billingCodeLabelResolverType satisfies (
  rootKey: BillingCodeLabelResolver$key,
) => Record<string, string> | null | undefined);
import { label as workForceTypeLookupLabelResolverType } from "../../../__resolvers__/WorkForceTypeLabelResolver";
// Type assertion validating that `workForceTypeLookupLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(workForceTypeLookupLabelResolverType satisfies (
  rootKey: WorkForceTypeLabelResolver$key,
) => Record<string, string> | null | undefined);
export type CostLinesFields_CostLineItemsFragment$data = ReadonlyArray<{
  readonly billable: boolean | null | undefined;
  readonly billingCode: {
    readonly billingSection: {
      readonly shortDescription: Record<string, string>;
    } | null | undefined;
    readonly code: number;
    readonly id: string;
    readonly label: Record<string, string> | null | undefined;
  };
  readonly billingCodeCategory: BillingCodeCategory;
  readonly craneIndex: number;
  readonly defaultQuantity: number | null | undefined;
  readonly id: string;
  readonly isFixedQuantity: boolean;
  readonly isFractionAllowed: boolean;
  readonly kind: CostLineKind;
  readonly quantity: number | null | undefined;
  readonly rate: string | null | undefined;
  readonly requireWorkForceType: boolean;
  readonly salesRateResult: {
    readonly canEditRate: boolean;
    readonly error: {
      readonly code: string;
      readonly description: string;
    } | null | undefined;
    readonly isBillable: boolean;
    readonly value: {
      readonly createdAt: string;
      readonly isAnnualContract: boolean;
      readonly isFlexiblePrice: boolean;
      readonly minimumQuantity: number;
      readonly price: string;
    } | null | undefined;
  } | null | undefined;
  readonly workForceType: {
    readonly code: number;
    readonly id: string;
    readonly label: Record<string, string> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "CostLinesFields_CostLineItemsFragment";
}>;
export type CostLinesFields_CostLineItemsFragment$key = ReadonlyArray<{
  readonly " $data"?: CostLinesFields_CostLineItemsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_CostLineItemsFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CostLinesFields_CostLineItemsFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "craneIndex",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "BillingCode",
        "kind": "LinkedField",
        "name": "billingCode",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "fragment": {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BillingCodeLabelResolver"
            },
            "kind": "RelayResolver",
            "name": "label",
            "resolverModule": require('./../../../__resolvers__/BillingCodeLabelResolver').label,
            "path": "billingCode.label"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSection",
            "kind": "LinkedField",
            "name": "billingSection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shortDescription",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requireWorkForceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkForceTypeLookup",
      "kind": "LinkedField",
      "name": "workForceType",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkForceTypeLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../../__resolvers__/WorkForceTypeLabelResolver').label,
          "path": "workForceType.label"
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFractionAllowed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingCodeCategory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFixedQuantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultQuantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesRateResult",
      "kind": "LinkedField",
      "name": "salesRateResult",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canEditRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isBillable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesRate",
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isAnnualContract",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isFlexiblePrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minimumQuantity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesRateError",
          "kind": "LinkedField",
          "name": "error",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CostLine",
  "abstractKey": null
};
})();

(node as any).hash = "68874d4bfce244d9f50af13b7d330ca6";

export default node;
